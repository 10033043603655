import { useGlobalContentStore } from '~/store/globalContent';

export default function useLocalDate() {
  const marketSettings = useGlobalContentStore().marketSettings;
  const getLocaleDate = (date: string, options:Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' }) => {
    const convert = new Date(date);
    return convert.toLocaleDateString(marketSettings.locale, options);
  };

  const getLocaleTime = (date: string) => {
    const convert = new Date(date);
    return convert.toLocaleTimeString(marketSettings.locale, { hour12: false, hour: '2-digit', minute: '2-digit' });
  };

  return {
    getLocaleDate,
    getLocaleTime,
  };
}
